import React from 'react';
import {navigate} from "gatsby";
import * as queryString from "query-string";
import { useMutation, gql } from "@apollo/client";
import { useWebStoreContext } from "../contexts/WebStoreContext";
import { useCustomerContext } from "../contexts/CustomerContext";
import useLocalStorage from "../utils/useLocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useLazyCustomerCart, useMergeCarts } from '../hooks/useCart';
import { useCartContext } from '../contexts/CartContext';


export default function AuthenticatePage({location}) {
    const { webstore, setWebstore } = useWebStoreContext();
    const { customer, setCustomer } = useCustomerContext();
    const { cart, updateCart } = useCartContext();
    const {code} = queryString.parse(location.search);
    const [authDestPath] = useLocalStorage('ont_auth_dest_path');
    const [customerTokenMutation, {called: customerTokenMutationCalled}] = useCustomerToken(code);
    const [customerCartQuery, {called: customerCartQueryCalled, data: customerCartData}] = useLazyCustomerCart();
    const [mergeCartsMutation] = useMergeCarts();

    if (typeof window==='undefined') return null;

    if (!customerTokenMutationCalled) {
        customerTokenMutation().then(data => {
            const designatedStore = data.data.generateCustomerTokenFromSsoCode.intended_store_view;
            setCustomer({
                token: data.data.generateCustomerTokenFromSsoCode.token,
                has_extra_permission: data.data.generateCustomerTokenFromSsoCode.has_extra_permission,
                intended_store_view: designatedStore,
            });
            setWebstore({...webstore, designatedStore: designatedStore});
        });
    }
    
    console.log('SSO auth code', code);

    if (customer.token && !customerCartQueryCalled) {
        customerCartQuery();
    }

    // we have a logged in customer and their cart
    if (customerCartQueryCalled && customer.token && customerCartData) {
        const customerCartId = customerCartData.customerCart.id;
        const guestCartId = cart.id?.length ? cart.id : null;
        if (guestCartId && guestCartId !== customerCartId) {
            mergeCartsMutation({variables: {guestCartId: guestCartId, customerCartId: customerCartId}})
                .then(data => updateCartAndRedirect(updateCart, data.data.mergeCarts, authDestPath))
                .catch(error => navigate(authDestPath ?? '/'));
        } else {
            updateCartAndRedirect(updateCart, customerCartData.customerCart, authDestPath)
        }
    }

    return (
        <center>
            <h2 style={{textAlign: 'center', fontWeight: 'lighter'}}>Logging you in...</h2>
            <p><FontAwesomeIcon icon={faSpinner} size="lg" spin /></p>
        </center>
    );
}

function updateCartAndRedirect(updateCart, newCart, authDestPath) {
    updateCart(newCart);
    navigate(authDestPath ?? '/');
}

function useCustomerToken(code) {
    return useMutation(gql`mutation {
        generateCustomerTokenFromSsoCode(
            ssoCode: "${code}"
        ) {
            token
            has_extra_permission
            intended_store_view
        }
    }`);
}
